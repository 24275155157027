<template>
    <div class="bulletin-list">
        <div class="manage-wrapper">
            <div class="table-wrapper">
                <h2 class="table-title">
                    <img src="../../assets/images/main/bulltin-icon.png" alt="" class="title-icon">项目周报</h2>
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%" @row-click="openPdf">
                        <el-table-column type="index" label="序号" style="background:#2373EC" align="center">
                        </el-table-column>
                        <el-table-column prop="name" label="周报名称" align="center"></el-table-column>
                        <el-table-column prop="createTime" label="上传时间" align="center"></el-table-column>
                    </el-table>
                    <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                        :limit.sync="pages.pageSize" @pagination="handlePageChange">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: 89,
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                tableData: [],
            }
        },

        methods: {
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getProjectBriefing();
            },
            async getProjectBriefing() {

                let params = {
                    projectId: this.$route.params.id,
                    // projectId: 194,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                }
                let resData = await this.$Api.Project.getProjectBriefing(params);
                // console.log(resData);
                if (JSON.stringify(resData.data.records) == '[]' || !resData) {
                    this.tableData = [];
                    return;
                }
                this.tableData = resData.data.records
                this.pages.total = resData.data.total
            },
            openPdf(row) {
                // console.log(row)
                this.$router.push(`/pdf/${row.identifier}?proid=${this.$route.params.id}&breadNumber=4`)
                this.$store.commit('getPdfUrl', row.identifier)
                this.$store.commit('getPdfName', row.name)
                // window.open(`${this.downloadURL}${row.identifier}`)
            },
        },
        mounted() {
            this.getProjectBriefing();
        }
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .bulletin-list {
        .table-wrapper {
            border-radius: 8px;
            padding-top: 10px;
            background: #fff;
            padding: 0 26px 24px;
        }

        .table-title {
            line-height: 68px;
            font-size: 17px;
        }

        .title-icon {
            width: 21px;
            height: 21px;
            margin-right: 9px;
            vertical-align: -4px;
        }

        /deep/ .el-table__row {
            cursor: pointer;
        }

        @media screen and (min-width:1250px) {
            .table-wrapper {
                padding: 0 43px 24px;
            }
        }
    }
</style>